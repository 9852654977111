<template>
  <router-view />
</template>

<script>
export default {
  name: 'Base',
  beforeMount() {
    const pathLocale = this.$route.params?.locale?.[0]
    if (pathLocale) {
      this.$store.dispatch('user/setLocale', pathLocale)
    }
  },
}
</script>

<style scoped></style>
